<template>
  <div class="comment-contaner">
    <Header></Header>
    <Nav></Nav>
    <Layout>
      <el-row class="pb16" type="flex" align="middle" justify="space-between">
        <div class="left f22 c3">
          <span>评价本次服务</span>
        </div>
        <div class="right">
          <button @click="toDevice">
            <img src="@/assets/images/home/return-img.png" alt="" />
          </button>
        </div>
      </el-row>
      <div class="line"></div>
      <!-- 订单详情start -->
      <div class="order-box mt32">
        <!-- 维修地址 -->
        <div class="item mb24">
          <p class="line1 f18 c3">设备详情</p>
          <p class="line2 mt20">
            <span class="f14 c3 mr32"
              >设备型号：<span class="f14 c6">{{
                deviceMsg.deviceModel ? deviceMsg.deviceModel : "-"
              }}</span></span
            >
            <span class="f14 c3 mr32"
              >设备品牌：<span class="f14 c6">{{
                deviceMsg.brandName ? deviceMsg.brandName : "-"
              }}</span></span
            >
            <span class="f14 c3 mr32"
              >公司名称：<span class="f14 c6">{{
                deviceMsg.companyName ? deviceMsg.companyName : "-"
              }}</span></span
            >
            <span class="f14 c3 mr32"
              >选择设备类型：<span class="f14 c6">{{
                deviceMsg.typeName ? deviceMsg.typeName : "-"
              }}</span></span
            >
          </p>
          <p class="line2 mt20">
            <span class="f14 c3 mr32"
              >控制系统：<span class="f14 c6">{{
                deviceMsg.controlName ? deviceMsg.controlName : "-"
              }}</span></span
            >
          </p>
        </div>
        <!-- 维修地址 -->
        <div class="form-box">
          <el-form
            label-width="90px"
            :model="ruleForm"
            :status-icon="false"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
            :hide-required-asterisk="true"
          >
            <!-- 评价：: -->
            <el-form-item class="mb20" label="评价:" required prop="content">
              <el-rate v-model="ruleForm.value1" :colors="colors"></el-rate>
            </el-form-item>
            <!-- 评价：:结束 -->
            <!-- 评价内容：: -->
            <el-form-item
              class="mb20"
              label="评价内容:"
              required
              prop="content"
            >
              <el-input
                type="textarea"
                v-model="ruleForm.content"
                placeholder="请输入您的评价"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <!-- 评价内容:结束 -->
            <!-- 添加照片 -->
            <el-form-item class="mb20" label="添加照片" required>
              <span class="c9 f14">最多可上传3张</span>

              <el-upload
                :action="objData.host"
                :data="objData"
                multiple
                :limit="3"
                list-type="picture-card"
                :before-upload="beforeUploadFun"
                :on-progress="progressFun"
                :on-remove="handleRemove"
                :on-success="videoSuccess"
                :on-change="handleChange"
                :file-list="fileList"
              >
                <!-- <i class="el-icon-plus" v-if="showAddSource"></i> -->
                <div slot="trigger" class="add-upload-box" v-if="showAddSource">
                  <i class="el-icon-plus"></i>
                </div>
                <!-- <el-button slot="trigger" size="small" type="primary"
                    >选取文件</el-button
                  > -->
              </el-upload>
            </el-form-item>
            <!-- 添加照片结束 -->
            <!-- 提交开始 -->
            <el-form-item>
              <el-button class="btn cf f16" @click="submitForm('ruleForm')"
                >提交</el-button
              >
            </el-form-item>
            <!-- 提交结束 -->
          </el-form>
        </div>
      </div>
    </Layout>
    <Footer></Footer>
    <!-- 弹窗 start -->
    <el-dialog
      :visible.sync="assderVisible"
      width="460px"
      :show-close="false"
      class="assderTc"
    >
      <span slot="title" class="title">
        <p class="fs18 font-33">提交成功</p>
        <img
          src="@/assets/images/home/close.png"
          @click="assderVisible = false"
          class="hand"
        />
      </span>

      <div class="ja-c fs14 font-66 w100">
        <p class="text">
          您已成功提交评价信息请耐心等待平台安排服务人员与您联系
        </p>
        <button class="btn" @click="confirm">确认</button>
      </div>
    </el-dialog>
    <!-- 弹窗结束 -->
  </div>
</template>

<script>
let flag = true;
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  components: {
    Layout,
    Header,
    Nav,
    Footer,
  },
  data() {
    var validateValue1 = (rule, value, callback) => {
      if (value === "" || value == 0) {
        callback(new Error("请选择评价星级"));
      }
      callback();
    };
    var validateContent = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入您的评价"));
      }
      callback();
    };
    return {
      assderVisible: false, //成功弹窗
      ruleForm: {
        content: "",
        value1: null, //评价星级
      },
      rules: {
        content: [{ validator: validateContent, trigger: "blur" }],
        value1: [{ validator: validateValue1, trigger: "blur" }],
      },
      colors: { 2: "#FF0015", 4: "#FF0015", 5: "#FF0015" },

      id: "", //id值

      deviceMsg: {}, //设备信息

      // 图片上传

      fileList: [],

      objData: {
        OSSAccessKeyId: "", //Bucket拥有者的AccessKey ID
        policy: "", //Policy规定了请求表单域的合法性
        Signature: "", //	根据AccessKey Secret和Policy计算的签名信息
        key: "", //	上传Object的名称  文件名
        host: "", //后台返回  类似https://tulingmall-xushu.com
        dir: "", //上传地址   类似tuling-test/2021-02   用户上传文件时指定的前缀 后台加过斜杠则不用再加
      },

      showConText: {},

      upImg: [], //上传后所有的图片

      upVideo: [], //上传后所有的视频

      upFile: [], //上传的文件

      upFileName: [], //上传文件名字

      videodialogVisible: false,
      dialogImageUrl: "",

      showAddSource: true, //添加按钮显示隐藏
    };
  },
  created() {
    this.id = this.$route.query.id;
    // 报修订单-通过id查询
    this.repairOrderById();
  },
  methods: {
    // 图片上传前函数
    beforeUploadFun(file) {
      var fileType = file.type.split("/")[0];
      if (fileType == "video") {
        this.$message.info("请注意不能选择视频");
        return;
      }
      // console.log("上传前函数", file);
      // 将文件名存入文件列表中
      // this.upFileName.push(file.name);
      // 将文件信息存入表格中
      let date = new Date();
      let curDate = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
      // this.tableData.push({
      //   name: file.name,
      //   date: curDate,
      // });

      // console.log(this.upFileName, "上传文件名");
      // 如果用户第一次上传了一张图片  那么第二次一定也要是一张图片  否则提示用户

      if (this.upImg.length) {
        // 用户已经不是第一次上传了  判断一定要是图片才可以
        // var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
        var fileType = file.type.split("/")[0];
        // let res = imglist.some((item) => {
        //   return item == fileType;
        // });
        // console.log(res, "结果");
        if (fileType != "image") {
          // 上传的不是图片
          // console.log("结果是图片");
          this.$message.info("请继续上传图片");
          this.fileList.pop();
          return false;
        }
      }

      return new Promise((rs, rj) => {
        //var filename = file.name;
        this.$API.getOssConfig().then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            this.objData.OSSAccessKeyId = this.showConText.accessid;
            this.objData.policy = this.showConText.policy; //不确定
            this.objData.Signature = this.showConText.signature; //不确定
            this.objData.host = this.showConText.host;
            var suffix = file.name.substring(file.name.lastIndexOf(".") + 1);
            var filename = this.showConText.fileName + "." + suffix;
            this.objData.key = filename; //不确定
            //this.objData.success_action_status = "200";
            //this.objData.dir = this.showConText.fileName;
            // console.log("上传成功的文件访问路径:");
            //console.log(`${this.objData.host}/${this.objData.dir}/${filename}`);
            // file.viewUrl = `${this.objData.host}/${this.objData.dir}/${filename}`;
            var videaUrl = this.objData.host + "/" + filename;
            console.log(videaUrl, "videaurl");

            // 如果用户上传的是视频  控制只能上传一个
            // 匹配 视频
            var urlArr = videaUrl.split(".");
            let houzhui = urlArr[urlArr.length - 1];
            // 视频格式
            var videolist = ["mp4", "m2v", "mkv"];
            let result = videolist.some(function (item) {
              return item == houzhui;
            });
            // 图片格式
            var imglist = ["png", "jpg", "jpeg", "bmp", "gif", "webp"];
            let resultImg = imglist.some(function (item) {
              return item == houzhui;
            });

            // 文件格式
            var filelist = ["docx", "pdf", "xlsx"];
            let resultFile = filelist.some(function (item) {
              return item == houzhui;
            });

            // 上传的是视频的处理
            if (result) {
              // result = "video";
              // return result;
              // 上传的是视频
              this.showAddSource = false;
              this.upVideo.push(videaUrl);
            }

            // 上传的是图片的处理
            if (resultImg) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              console.log("上传的是图片");
              this.upImg.push(videaUrl);
            }

            // 上传的是文件的处理
            if (resultFile) {
              // result = "video";
              // return result;
              // 上传的是视频
              // this.showAddSource = false;
              // console.log("上传的是图片");
              this.upFile.push(videaUrl);
            }

            // 如果用户上传的是图片  控制可以上传三个
            // 匹配 视频
            // var urlArr = videaUrl.split(".");
            // let houzhui = urlArr[urlArr.length - 1];
            // var videolist = ["mp4", "m2v", "mkv"];
            // let result = videolist.some(function (item) {
            //   return item == houzhui;
            // });
            // if (result) {
            //   // result = "video";
            //   // return result;
            //   // 上传的是视频
            //   this.showAddSource = false;
            // }
            console.log(this.upImg, "upImg");
            if (this.upImg.length == 3) {
              this.showAddSource = false;
            }

            if (this.upFile.length == 3) {
              this.showAddSource = false;
            }
            console.log(this.upImg);
            rs(true);
          }
        });
      });
    },

    //根据分组查询系统配置
    getOssConfig() {
      this.$API
        .getOssConfig()
        .then((res) => {
          if (res.code == 1) {
            this.showConText = res.data;
            // console.log(this.showConText);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    progressFun() {
      // console.log("正在上传");
    },

    //删除图片
    handleRemove(file, fileList) {
      console.log(file.url, "fileurl");
      console.log(this.fileList, "filelist");
      this.fileList.forEach((item, index) => {
        // console.log(item.url);
        if (item.url == file.url) {
          this.fileList.splice(index, 1);
          console.log(index);
          this.upFile = this.upFile.filter((item, delIndex) => {
            return delIndex != index;
          });
          this.upFileName = this.upFileName.filter((item, delIndex) => {
            return delIndex != index;
          });
          this.tableData = this.tableData.filter((item, delIndex) => {
            return delIndex != index;
          });
        }
      });
      this.showAddSource = true;
      // console.log(this.upFile, "删除了upFile");
      // console.log(this.upFileName, "删除了upFileName");
    },

    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    },

    // 图片上传方法
    videoSuccess(res, file, fileList) {
      // this.findvideocover(file.url, file);
    },

    // handleSuccess(file, fileList) {
    //   this.tableData.push({
    //     name: fileList.name,
    //     date: fileList.uid,
    //   });
    // },
    // 报修订单-通过id查询
    repairOrderById() {
      let params = {
        id: this.id,
      };
      this.$API
        .repairOrderById(params)
        .then((res) => {
          if (res.code == 1) {
            this.deviceMsg = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 弹窗确定
    confirm() {
      this.assderVisible = false;
      setTimeout(() => {
        this.$router.back();
      }, 500);
    },
    //弹窗方法
    assderHandle() {
      this.assderVisible = false;
    },
    // 返回
    toDevice() {
      this.$router.push("/contact/order");
    },
    // 提交
    submitForm(formName) {
      if (!flag) {
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 图片
          let fileName;
          let filelistStr;
          let filelistNameStr;
          fileName = this.ruleForm.fileName;
          filelistStr = this.upImg.map((item) => item).join(",");
          filelistNameStr = this.upImg.map((item) => item).join(",");
          if (!filelistStr) {
            this.$message.info("请添加图片");
            return;
          }
          flag = false;

          let params = {
            evaluateGrade: this.ruleForm.value1, //评价分数
            evaluateContent: this.ruleForm.content, //评价内容
            id: Number(this.id),
            orderStatus: 7,
            evaluateImgs: filelistStr, //评价图片
            evaluateTime: new Date(),
          };
          this.$API
            .toComment(params)
            .then((res) => {
              if (res.code == 1) {
                // 打开确认成功弹窗
                this.assderVisible = true;
                flag = true;
              } else {
                flag = true;
                this.$message.info(res.msg);
              }
            })
            .catch((err) => {
              flag = true;
              console.log(err);
            });

          //   alert("submit!");
        } else {
          //   console.log("error submit!!");
          //   return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.comment-contaner {
  .add-upload-box {
    width: 83px !important;
    height: 83px !important;
    line-height: 83px !important;
    border: 1px dotted #999;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 83px !important;
    height: 83px !important;
    border: none !important;
  }
  .el-upload--picture-card {
    width: 0px;
    height: 0px;
    margin: 0px;
    padding: 0px;
    // opacity: 0px;
    color: #ffffff;
    border: none !important;
    // width: 83px !important;
    // height: 83px !important;
    // line-height: 83px !important;
    // display: none;
  }
  .el-upload-list__item-thumbnail {
    width: 83px !important;
    height: 83px !important;
  }
  .right {
    button {
      img {
        width: 72px;
        height: 24px;
      }
    }
  }
  //   订单详情
  //   订单详情
  .order-box {
    .item {
      width: 1000px;
      min-height: 107px;
      box-sizing: border-box;
      padding: 24px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #999999;
    }
    .img-box {
      margin-left: 20px;
      img {
        width: 94px;
        height: 94px;
        margin-right: 10px;
      }
    }
  }
  //   表单
  .form-box {
    .el-rate {
      margin-top: 10px !important;
    }
    margin-left: 24px;
    // 性別
    .item {
      display: inline-block;
      margin-left: 20px;
      cursor: pointer;
      img {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
    .item2 {
      margin-left: 56px;
    }
    //input表单样式
    .el-input__inner {
      width: 240px;
      height: 40px;
    }
    //input下边距
    .el-form-item {
      margin-bottom: 20px;
    }
    //label样式
    .el-form-item__label {
      text-align: left;
    }
    //多行文本样式
    .el-textarea__inner {
      width: 400px;
      height: 104px;
      background: #ffffff;
      border-radius: 2px 2px 2px 2px;
    }
    //按钮样式
    .btn {
      width: 214px;
      height: 50px;
      background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
      border-radius: 8px 8px 8px 8px;
      color: #ffffff;
      margin-top: 48px;
      margin-left: 347px;
    }
  }
  // 自定义弹窗样式
  .assderTc {
    // 对话框头部
    .el-dialog__header {
      padding: 0px !important;
    }
    // 对话框内容
    .el-dialog__body {
      padding: 0px !important;
    }
    display: flex;
    align-items: center;
    .title {
      padding-top: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      img {
        width: 32px;
        height: 32px;
        position: absolute;
        top: 24px;
        right: 32px;
      }
    }
    ::v-deep .el-dialog__header {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 0 !important;
    }
    ::v-deep .el-dialog__body {
      padding: 0;
    }
    ::v-deep .el-dialog__footer {
      padding: 0;
    }
    ::v-deep .el-dialog {
      border-radius: 10px;
    }
    .ja-c {
      margin: 0 auto;
      margin-top: 24px;
      .text {
        width: 336px;
        height: 45px;
        font-size: 16px;
        color: #666666;
        line-height: 24px;
        margin-left: 62px;
        text-align: center;
      }
      .btn {
        width: 140px;
        height: 42px;
        background: linear-gradient(96deg, #ed1b23 0%, #f15f64 100%);
        box-shadow: 0px 4px 25px 1px rgba(237, 27, 35, 0.2);
        border-radius: 8px 8px 8px 8px;
        color: #ffffff;
        margin-top: 37px;
        margin-bottom: 32px;
        margin-left: 160px;
      }
    }

    .btnad {
      width: 112px;
      height: 40px;
      background: rgba(246, 247, 249, 1);
      border: 1px solid #ecedef;
      opacity: 1;
      border-radius: 20px;
    }
    .btnsad {
      color: #333;
      width: 112px;
      height: 40px;
      background: rgba(255, 195, 44, 1);
      opacity: 1;
      border-radius: 20px;
    }
  }
}
</style>